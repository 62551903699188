body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

p > a {
  color: #0071bc;
  text-decoration: underline;
}

.carousel .slider-wrapper.axis-horizontal .slider .slide {
  display: flex;
  align-items: baseline;
  justify-content: center;
}

/* fix stripe odd styles */
[id="cc.number"],
[id="cc.exp"],
[id="cc.cvc"] {
  background: revert !important;
}